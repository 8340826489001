import { useCallback } from 'react';
import { checkEmail, login as loginRequest, logout } from '@/services/user';
import { useRequest } from 'ahooks';
import type { BaseOptions } from '@ahooksjs/use-request/lib/types';
import { cryptSaltPsw, getTimestamp, randomString } from '@/utils/utils';
import { history, useLocation, getLocale } from 'umi';
import { fetchRecord } from '@/services/finance';

export interface LoginHookProps {
  checkOptions?: BaseOptions<any, any>;
  loginOptions?: BaseOptions<any, any>;
}

export interface LoginhookResult {
  login: (params: toginType, platform?: any) => void;
  loading: boolean;
  simulationLogin: (callback: () => void) => void;
  handleRedirect: (platform?: any) => void;
}

type toginType = {
  email: string;
  password: string;
};

export default function useLogin(props?: LoginHookProps): LoginhookResult {
  const { checkOptions, loginOptions } = props || {};
  const location = useLocation() as any;
  const { run: checkRun, loading: checkLoading } = useRequest<{ success: boolean }, any>(checkEmail, {
    manual: true,
    ...checkOptions,
  });

  const { run: runLogout, loading: logoutLoading } = useRequest<{ success: boolean }, any>(logout, {
    manual: true,
  });

  const { run: loginRun, loading: loginLoading } = useRequest<{ success: boolean }, any>(loginRequest, {
    manual: true,
    ...loginOptions,
  });

  // 跳转地址
  const { run: runCheckRecharge, loading: rechargeLoading } = useRequest(fetchRecord, {
    manual: true,
    onError: () => {
      history.push('/product/instance');
    },
  });

  const handleRedirect = async (platform?: any) => {
    const from = sessionStorage.getItem('from');
    const rechargeRes = await runCheckRecharge({
      orderStatus: 'SUCCESS',
      page: 1,
      pageSize: 1,
    });
    // 2022-8-31 如果当前存在from来自需求池，则跳转回官网
    if (from && platform) {
      sessionStorage.removeItem('from');
      window.location.href = platform?.officialDomainName
        ? `//${platform.officialDomainName}/${getLocale()}/${from}`
        : '/';
    } else if (rechargeRes?.data?.length) {
      history.push('/product/instance');
    } else {
      history.push('/product/instance/create');
    }
  };

  // 登录方法
  const login = useCallback(async ({ email, password }: toginType, platform: any) => {
    const checkRes = await checkRun({ email });
    if (checkRes.success) {
      const salt = randomString();
      const userPwd = cryptSaltPsw(salt, password);
      const loginRes: any = await loginRun({
        loginMethod: 'BY_PASSWORD',
        salt,
        timestamp: getTimestamp(),
        userIdentifier: email,
        userPwd,
      });
      if (loginRes?.success) {
        if (loginRes?.data?.twoFAuth) {
          //双因子认证，twoFAuth
          history.push(`/user/twoFAuth?state=${loginRes.data.state}`);
        } else if (loginRes?.data?.userInfoWeb?.userSubStatus === 'LOCK_BANNED') {
          history.push('/ban');
        } else {
          // 2022-2-14 修改接口
          handleRedirect(platform);
        }
      }
    }
  }, []);

  // 模拟登录
  const simulationLogin = async (callback?: () => void) => {
    const { userName, randomPassword, supervisorUserId, supervisorUserName } = location.query || {};
    try {
      if (userName && randomPassword && supervisorUserId && supervisorUserName) {
        await runLogout();
        const loginParam = {
          userName,
          randomPassword,
          userIdentifier: supervisorUserName,
          supervisorUserId,
          loginMethod: 'BY_SUPERUSER_SIMULATIED',
        };
        const loginRes: any = await loginRun(loginParam);
        if (loginRes.success) {
          if (loginRes?.data?.userInfoWeb?.userSubStatus === 'LOCK_BANNED') {
            history.push('/ban');
          } else {
            handleRedirect();
          }
        }
      }
    } finally {
      await callback?.();
    }
  };

  return {
    login,
    simulationLogin,
    loading: checkLoading || loginLoading || logoutLoading || rechargeLoading,
    handleRedirect,
  };
}
