import { history, useModel } from 'umi';
import { useCallback } from 'react';
import { logout } from '@/services/user';
import { useRequest } from 'ahooks';
import { getTimestamp } from '@/utils/utils';

export interface LoginhookResult {
  logout: () => void;
  loading: boolean;
}

export default function useLogout(): LoginhookResult {
  const { run, loading } = useRequest<{ success: boolean }, any>(logout, {
    manual: true,
  });
  const masterProps = useModel<any>('@@qiankunStateForSlave');

  // 登录方法
  const fn = useCallback(async () => {
    const checkRes = await run({ _: getTimestamp() });
    if (checkRes.success) {
      history.push('/user/login');
    }
    masterProps?.setMasterState((state: any) => ({
      ...state,
      user: undefined,
    }));
  }, []);

  return {
    logout: fn,
    loading,
  };
}
