import type { ResponseError } from 'umi-request';
import { extend } from 'umi-request';
import { notification } from 'antd';
import { headersAuthToken } from './authToken';
import { masterHistory } from './utils';
import { localeInfo, getLocale } from 'umi';
let controller = new AbortController();
let signal = controller.signal; // 返回一个 AbortSignal 对象实例，它可以用来 with/abort 一个 DOM 请求。
// 需要抛出异常且不做操作的接口
const thowErrorUrl: string[] = [
  '/userApi/portal/checkEmailIfUnique.do',
  '/userApi/common/sendEmailVerifyCode.do',
  '/userApi/portal/checkEmailIfExist.do',
  '/userApi/portal/login.do',
  '/userApi/oauth/callback/google.do',
  '/userApi/register/callback/google.do',
  '/userApi/portal/checkLink.do',
  '/userApi/portal/resetPasswordByLink.do',
  '/userApi/portal/user/ip.do',
  '/userApi/oauth/callback/github.do',
  '/userApi/register/callback/github.do',
  '/userApi/portal/twoFALogin.do',
];

const lanuageUrl: string[] = [
  '/api/payment-app/v1/recharge/online',
  '/api/payment-app/v1/recharge/self',
  '/api/payment-app/v1/recharge/discount/self/available',
  '/api/payment-app/v1/stripe/credit_card/self',
  '/api/payment-app/v1/stripe/credit_card/',
  '/api/payment-app/v1/recharge/',
];

/**
 * 异常处理程序
 * 最后执行,需要在response中throw异常才可以，return/throw结果不同
 */
const errorHandler = (error: ResponseError<any>) => {
  const lang = getLocale() || 'en-US';

  const resUrl = error?.request?.url;
  const resMessage = error?.data?.message;
  const errorCode = error?.data?.code as string;
  const resStatus = error.response?.status;
  if (error.name === 'AbortError') {
    return { success: false };
  }
  const refreshSign = function () {
    controller.abort();
    controller = new AbortController();
    signal = controller.signal;
  };
  if (!error.response) {
    openNotificationWithIcon('error', localeInfo[lang]?.messages?.['request.error.overtime']);
    // masterHistory.replace(`//${window.location.host}/user/login`);
    return {
      code: 1,
      success: false,
      msg: resMessage,
    };
  }
  if (resStatus === 401 || ['NoUrlAuthority'].includes(errorCode)) {
    // localStorage.clear();
    openNotificationWithIcon('error', localeInfo[lang]?.messages?.['request.error.unlogin'], resUrl);
    refreshSign();
    window.location.href = '/user/login';
    // masterHistory.replace(`//${window.location.host}/user/login`); //只改变url，实际还是停留在原页面
    return;
  }
  if (resStatus === 400 && error.data === '与服务器时间相差较大，请校验系统时间') {
    // 接口安全防护拦截
    openNotificationWithIcon('error', localeInfo[lang]?.messages?.['request.error.difference.time']);
    return;
    // throw error
  }
  if (resStatus === 400 && resMessage && resMessage.trim() === '无URL访问权限') {
    openNotificationWithIcon('error', localeInfo[lang]?.messages?.['request.error.login.overtime'], resUrl);
    refreshSign();
    masterHistory.replace(`//${window.location.host}/user/login`);
    // throw error
    return {
      code: 1,
      success: false,
      msg: resMessage,
    };
  }
  if (resStatus === 400 && resMessage && resMessage.trim() === '内部错误') {
    openNotificationWithIcon('warning', localeInfo[lang]?.messages?.['request.error.network'], resUrl);
    // throw error;
    return {
      code: 1,
      success: false,
      msg: '网络错误，请稍后再试！',
    };
  }
  const flag = thowErrorUrl.find((item) => {
    return error.response.url?.indexOf(item) !== -1;
  });
  if (flag) {
    throw error;
  }
  if (resStatus === 400 && resMessage) {
    openNotificationWithIcon('error', resMessage, resUrl);
    // throw error;
    return {
      code: 1,
      success: false,
      msg: resMessage,
    };
  }

  const msg =
    resMessage ||
    error.data?.code ||
    localeInfo[lang]?.messages?.[`request.error.login.request.error.${resStatus || 'unknown'}`];

  if (!flag) {
    openNotificationWithIcon('error', msg);
  }
  return {
    code: 1,
    success: false,
    msg,
    innerCode: error.data?.innerCode || '',
  };
  // throw error
};

const request = extend({
  credentials: 'include',
  timeout: 60000,
  errorHandler,
  crossOrigin: true,
});

// 拦截请求
request.interceptors.request.use((url, options) => {
  const flag = lanuageUrl.find((item) => {
    return url?.indexOf(item) !== -1;
  });
  const lang = getLocale() || 'en-US';
  const newOptions = {
    ...options,
    headers: {
      Authorization: headersAuthToken(),
      // TODO: 2022-11-21 后端暂未增加多语言，所以法语和越语统一传英文参数
      language: flag
        ? lang === 'en-US' || lang === 'fr-FR' || lang === 'vi-VN' || lang === 'ja-JP' || lang === 'ru-RU'
          ? 'en'
          : lang
        : 'en',
    },
  };
  return {
    url,
    options: { ...newOptions, signal: signal },
  };
});
// 拦截响应
request.interceptors.response.use(async (response: any, options) => {
  let data;
  if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 304) {
    try {
      if (options?.responseType === 'blob') {
        data = { data: await response.blob() };
      } else {
        data = (await response.clone().json()) || {};
      }
    } catch (error) {
      data = {};
    }
    // 成功情况处理解构data
    return { success: true, ...data };
  }
  data = await response.clone().json();
  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw { response, data };
});

// 提示框
function openNotificationWithIcon(type: any, description: any, key?: string) {
  notification.open({
    type,
    key,
    message: description,
  });
}

export default request;
